
import React, { memo, ReactElement } from 'react';

import Loader from '../Loader/Loader';

import './LoadingView.css';

export const BASE_CLASS = 'loading-view';

export function LoadingView(): ReactElement<{}> {
  return (
    <div className={BASE_CLASS}>
      <Loader />
      <p>Loading calculator</p>
    </div>
  );
}

export default memo(LoadingView);
