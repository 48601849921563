
import React, { memo, ReactElement } from 'react';

import { ICalculatorViewProps } from './ICalculatorView';

import './CalculatorView.css';

export const BASE_CLASS = 'calculator-view';
export const WRAPPER_CLASS = `${BASE_CLASS}__wrapper`;

export function CalculatorView(props: ICalculatorViewProps): ReactElement<ICalculatorViewProps> {
  return (
    <div className={BASE_CLASS}>
      <div className={WRAPPER_CLASS} data-calculatorid={props.id}></div>
    </div>
  );
}

export default memo(CalculatorView);
