
import React from 'react';

import { IAppState, IAppProps } from './IApp';

import { Header } from './components/Header/Header';
import CalculatorView, { WRAPPER_CLASS } from './components/CalculatorView/CalculatorView'
import LoadingView from './components/LoadingView/LoadingView';
import UnavailableView from './components/UnavailableView/UnavailableView';
import ErrorView from './components/ErrorView/ErrorView';

import './App.css';



export default class App extends React.PureComponent<IAppProps, IAppState> {

  public state = {
    scriptLoaded: false,
    scriptLoadError: false,
    unavailable: false,
  };

  public async componentDidMount(): Promise<void> {
    try {
      const id = this.props.locationService.getIDFromPathname();

      if (!id) {
        return this.setState(state => ({
          ...state,
          scriptLoaded: false,
          scriptLoadError: false,
          unavailable: true
        }));
      }

      const canInstall = await this.props.validationService.canInstall(id);

      if (!canInstall) {
        return this.setState(state => ({
          ...state,
          scriptLoaded: false,
          scriptLoadError: false,
          unavailable: true,
        }));
      }

      await this.props.scriptService.loadScript(process.env.REACT_APP_SCRIPT_URL);
      return this.setState(state => ({
        ...state,
        unavailable: false,
        scriptLoaded: true,
        scriptLoadError: false,
      }));
    } catch {
      this.setState(state => ({
        ...state,
        scriptLoaded: false,
        scriptLoadError: true,
      }));
    }
  }

  public componentDidUpdate(_: IAppProps, prevState: IAppState): void {
    if (!prevState.scriptLoaded && this.state.scriptLoaded && 'CalconicCalculator' in window) {
      const id = this.props.locationService.getIDFromPathname();
      console.log('What I got here, nigga:', id);
      if (!id) {
        return this.setState(state => ({
          ...state,
          scriptLoadError: false,
          unavailable: true,
        }));
      }

      new CalconicCalculator(id, void 0, `.${WRAPPER_CLASS}`);
    }
  }

  public render(): React.ReactNode {
    switch (true) {
      case this.state.unavailable:
        return <UnavailableView />;
      case !this.state.scriptLoaded && !this.state.scriptLoadError:
        return <LoadingView />;
      case this.state.scriptLoaded:
        return (
          <div>
            <Header onInstallClick={this.onInstallClick} />
            <CalculatorView id={this.props.locationService.getIDFromPathname() ?? ''} />
          </div>
        );
      case this.state.scriptLoadError:
        return <ErrorView />;
      default:
        return null;
    }
  }

  protected onInstallClick = (): void => {
    const id = this.props.locationService.getIDFromPathname();

    if (!id) {
      return;
    }

    window.location.replace(`${process.env.REACT_APP_INSTALL_URL}/${id}`);
  }
}
