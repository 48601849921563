
import React, { memo, useCallback, ReactElement } from 'react';

import { IHeaderProps } from './IHeader';

import logo from '../../calconic-calculator-builder.png';

import './Header.css';

export const BASE_CLASS = 'header';

export function Header(props: IHeaderProps): ReactElement<IHeaderProps> {
  const onInstallClick = useCallback(() => props.onInstallClick(), [props]);

  return (
    <div className={BASE_CLASS}>
      <img src={logo} alt="CALCONIC_ Build and add custom calculators to your website" />

      <h2>The calculator owner wants to share this calculator with you</h2>

      <button onClick={onInstallClick}>Add to my account</button>
    </div>
  );
}

export default memo(Header);
