
import React, { memo, ReactElement } from 'react';

import './ErrorView.css';

export const BASE_CLASS = 'error-view';

export function ErrorView(): ReactElement<{}> {
  return (
    <div className={BASE_CLASS}>
      <p>There was an error loading the calculator...</p>
    </div>
  );
}

export default memo(ErrorView);
