
import { IValidationService } from './IValidationService';

export class ValidationService implements IValidationService {

  constructor(protected readonly API_URL: string) {}

  public async canInstall(id: string): Promise<boolean> {
    try {
      const response = await fetch(`${this.API_URL}/calculator/installable/${id}`);
      return response.status < 400;
    } catch (error) {
      throw error;
    }
  }
}
