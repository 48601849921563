
import React, { memo, ReactElement } from 'react';

import './UnavailableView.css';

export const BASE_CLASS = 'unavailable-view';

export function UnavailableView(): ReactElement<{}> {
  return (
    <div className={BASE_CLASS}>
      <p>The calculator is unavailable</p>
    </div>
  );
}

export default memo(UnavailableView);
