
import { IScriptService } from './IScriptService';


export class ScriptService implements IScriptService {

  public async loadScript(src: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.addNodeToDOM(this.buildScriptNode(src, resolve, reject));
    });
  }

  protected buildScriptNode(src: string, onLoad: () => void, onError: () => void): HTMLScriptElement {
    const node = document.createElement('script');
          node.src = src;
          node.async = true;
          node.defer = true;
          node.onload = onLoad;
          node.onerror = onError;
    
    return node;
  }

  protected addNodeToDOM(node: HTMLScriptElement): void {
    document.body.appendChild(node);
  }
}
