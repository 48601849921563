
import 'typeface-lato';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ValidationService } from './services/ValidationService/ValidationService';
import { LocationService } from './services/LocationService/LocationService';
import { ScriptService } from './services/ScriptService/ScriptService';


function init(): void {
  const validationService = new ValidationService(process.env.REACT_APP_API_URL);
  const locationService = new LocationService(window.location);
  const scriptService = new ScriptService();

  ReactDOM.render(
    <App
      validationService={validationService}
      locationService={locationService}
      scriptService={scriptService}
    />,
    document.getElementById('root'),
  );
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

init();
