
import { ILocationService } from './ILocationService';


export class LocationService implements ILocationService {

  public static ID_MATCH_PATTERN = /\/([a-zA-Z0-9]{24})$/;

  public static NO_LOCATION_ERROR = new Error('Could not get ID from current pathname. Location object not available in context');

  constructor(protected readonly location: Location) {}

  /**
   * Gets the Calculator ID from window.location.pathname
   */
  public getIDFromPathname(): string | undefined {
    if (!this.location) {
      throw LocationService.NO_LOCATION_ERROR;
    }

    const matches = this.location.pathname.match(LocationService.ID_MATCH_PATTERN);

    if (!matches || !matches.length) {
      return void 0;
    }

    return matches[1];
  }
}
